import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { headers } from "../../Config/settings.js";
import { Routes as AppRoutes } from "../../navigation/Routes";
import { apis } from "../../Config/apis";

function RegisterEmail({ email, externalId }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const submitEmail = async (email) => {
    try {
      const response = await fetch(apis.launchRo, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ email }),
      });

      const responseData = await response.json();

      if (response.ok) {
        const urlString = responseData.roLink;
        const url = new URL(urlString);
        const params = url.searchParams;
        const existingValue = params.get("existing") || "false";

        if (existingValue !== "successful") {
          const path = `/${AppRoutes.LaunchConsent}`;
          navigate(`${path}?${params.toString()}`);
          return;
        }

        // Use navigate from react-router-dom for navigation, avoiding window.location.href
        const path = "/Resume";
        navigate(`${path}?${params.toString()}`);
      } else {
        // Handle non-successful responses
        console.error("Error submitting email:", responseData.message);
        alert(responseData.message || "Error submitting email");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while submitting the email.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    await submitEmail(email);

    if (externalId) {
      if (window.analytics) {
        window.analytics.alias(externalId, {});
      }
    }

    if (window.analytics) {
      window.analytics.identify(externalId, { email });
    }
  };

  return (
    <button
      className="button"
      onClick={handleSubmit}
      type="submit"
      disabled={isSubmitting}
    >
      {isSubmitting ? "Registering..." : `Let's go`}
    </button>
  );
}

export default RegisterEmail;
