// src/redux/slices/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  goal: [],  // An array to store goals
  cdrid: '',  // User ID
  existing: false,  // Boolean value indicating if the user is existing
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setGoal: (state, action) => {
      state.goal = action.payload;
    },
    setCdrid: (state, action) => {
      state.cdrid = action.payload;
    },
    setExisting: (state, action) => {
      state.existing = action.payload;
    },
    setEmail: (state, action) => {
      state.existing = action.payload;
    },
  },
});

export const { setGoal, setCdrid, setExisting, setEmail } = userSlice.actions;

export default userSlice.reducer;
