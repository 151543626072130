import React, { useEffect } from 'react';
import './shared.css';
import RegisterEmail from './Forms/RegisterEmail';
import Logo from './Images/Citro_logo.svg';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';


function WeCanHelp() {
  const location = useLocation();
  const { goal } = location.state || {};
  const { externalId } = location.state || {};


 useEffect(() => {
 console.log('GOAL:', goal);
 console.log('externalId:', externalId)
 ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }, []);
      

    
return (
<div className="container">
<div className="logo-box">
    {<img src={Logo} alt="Logo" className="logo-image" />}
        
      </div>
  <div className="header">
    <h1 className="title">Looks like we can help you with your money goal!</h1>
    <p className="subtitle">We help people over 50s save hundreds of dollars by claiming cashback rewards they missed out on.</p>
  </div>


 
  <RegisterEmail externalId={externalId} goal={goal}/>
</div>

)
}

export default WeCanHelp;