import React, { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga4';
import { CircularProgress } from '@mui/material';

function MissedRewards({ onDataLoaded }) {
    const [isLoading, setIsLoading] = useState(true);
    const [totalCashBack, setTotalCashBack] = useState(0);
    const consecutiveNonZeroCountRef = useRef(0); // Tracks non-zero counts.
    const attemptsRef = useRef(0); // Tracks attempts to avoid exceeding max attempts.

    // Helper function to make the API call and return the data.
    const fetchRewardData = async () => {
        const cdrid = localStorage.getItem('cdrid');

        const response = await fetch('https://minerva.sipora.io/api/client/rewards-optimiser/return-rewards', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ cdrid }),
        });

        if (!response.ok) {
            throw new Error('API call failed');
        }

        const data = await response.json();
        return data;
    };

    const startPolling = () => {
        // Clear consecutive count and start polling
        consecutiveNonZeroCountRef.current = 0;
        attemptsRef.current = 0;
        const maxAttempts = 24; // 120 seconds / 5 seconds = 24 attempts.

        const intervalId = setInterval(async () => {
            try {
                attemptsRef.current++;

                // Stop after max attempts
                if (attemptsRef.current >= maxAttempts) {
                    clearInterval(intervalId);
                    setIsLoading(false);
                    return;
                }

                const data = await fetchRewardData();
                const rewardValue = data[0].totalRewardValue;

                // Check if the value is non-zero
                if (rewardValue > 0) {
                    consecutiveNonZeroCountRef.current++;

                    // Track the event using Segment analytics
                    window.analytics.track('Total Cashback Retrieved', {
                        totalMissedCashback: rewardValue,
                    });

                    // If we've seen 3 consecutive non-zero values, stop polling
                    if (consecutiveNonZeroCountRef.current >= 3) {
                        setTotalCashBack(rewardValue);
                        clearInterval(intervalId);
                        setIsLoading(false);
                    }
                } else {
             
                    consecutiveNonZeroCountRef.current = 0;
                }
            } catch (error) {
                console.error('Error during polling:', error);
                clearInterval(intervalId); // Stop polling on error
                setIsLoading(false);
            }
        }, 5000); // Retry every 5 seconds
    };

    // Initial fetch and trigger polling if necessary
    const fetchData = async () => {
        try {
            setIsLoading(true);
            const data = await fetchRewardData();
            const rewardValue = data[0].totalRewardValue;

            if (rewardValue === 0) {
                // Start polling if initial value is 0
                startPolling();
            } else {
                // If we have a non-zero value, no need to poll
                setTotalCashBack(rewardValue);
                setIsLoading(false);

                // Track the event using Segment analytics
                window.analytics.track('Total Cashback Retrieved', {
                    totalMissedCashback: rewardValue,
                });
            }
        } catch (error) {
            console.error('API call failed:', error);
            setIsLoading(false);
        }
    };

    // On mount, fetch data
    useEffect(() => {
        fetchData();
    }, []); // Empty dependency array means it will only run once on component mount

    // Store the value of totalCashBack in local storage
    useEffect(() => {
        if (totalCashBack > 0) {
            const formattedTotalCashBack = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalCashBack);
            localStorage.setItem('totalCashBack', formattedTotalCashBack);
        }
    }, [totalCashBack]);

    return (
        <div className="points-display">
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress /> {/* MUI Loading Spinner */}
                </div>
            ) : (
                <>
                    <div className="points2">
                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalCashBack)}
                    </div>
                    <div className="points-label">
                        *Potential cashback earnings with Citro
                    </div>
                </>
            )}
        </div>
    );
}

export default MissedRewards;
