import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import LandingPage from './Components/LandingPage';
import LaunchConsent from './Components/LaunchConsent';
import Resume from './Components/Resume';
import Consented from './Components/ConsentedWithJobId';
import ConsentedSuccess from './Components/ConsentedSuccess';
import ShowMissed from './Components/ShowMissed';
import Income from './Components/Income';
import WeCanHelp from './Components/WeCanHelp';
import ShowMissedAlt from './Components/ShowMissedAlt';
import EntryPageGuest from './Components/EntryPageGuest';
import ContinueWithEmail from './Components/ContinueWithEmail';

import { Routes as AppRoutes } from './navigation/Routes';

function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const email = queryParams.get('email') ?? '';
  const externalId = queryParams.get('externalId') ?? '';

  return (
    <Router>
      <Routes>
        <Route path="/LandingPage" element={<LandingPage />} />
        <Route
          path="/"
          element={
            externalId && email ? (
              <Navigate
                replace
                to={`/${AppRoutes.ContinueWithEmail}?externalId=${externalId}`}
                state={{ email, externalId }}
              />
            ) : (
              <EntryPageGuest />
            )
          }
        />
        <Route
          path={`/${AppRoutes.ContinueWithEmail}`}
          element={<ContinueWithEmail />}
        />
        <Route path="/Income" element={<Income />} />
        <Route path="/WeCanHelp" element={<WeCanHelp />} />
        <Route
          path={`/${AppRoutes.LaunchConsent}`}
          element={<LaunchConsent />}
        />
        <Route path="/Resume" element={<Resume />} />
        <Route path="/Consented" element={<Consented />} />
        <Route path="/ConsentedSuccess" element={<ConsentedSuccess />} />
        <Route path="/ShowMissed" element={<ShowMissed />} />
        <Route path="/ShowMissedAlt" element={<ShowMissedAlt />} />
      </Routes>
    </Router>
  );
}

export default App;
