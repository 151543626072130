const apiKey = 'a^w&KGLQ3pg8mfxyYwGSb7RZcmiPa6Aewngj5uXSKBb8!r6Vu$CyQ%pQCHeh9MV!';
const tenantId = 'CITRO';

export const headers = {
        'Content-Type': 'application/json',
        'tenantId': tenantId,
        'apiKey': apiKey
}

export const joinLink ={
 mobile: 'https://link.joincitro.com.au/bBynCCe4YMb', 
 web: 'https://link.joincitro.com.au/YfEgvVVHQMb'
}

export const goals = [
    "save money",
    "pay off my debt",
    "buy a house",
    "go on a dream trip"
  ];