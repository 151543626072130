import React, { useEffect, useState } from 'react';
import './shared.css';
import Logo from './Images/Citro_logo.svg';
import ReactGA from 'react-ga4';
import MissedRewards from './Forms/MissedRewards';
import RewardsList from './Forms/RewardsList';
import { joinLink } from '../Config/settings.js'
import MissedRewardsAlt from './Forms/MissedRewardsAlt.js';

function ShowMissed() {

      const [isSubmitting, setIsSubmitting] = useState(false);
      const [textToShow, setTextToShow] = useState('')
   
      useEffect(() => {
       ReactGA.send({ hitType: "pageview", page: window.location.pathname });
       }, []);
      
      

      const handleDataLoaded = (totalCashBack) => {
        console.log('received total cashback:', totalCashBack);
    
        const goal = localStorage.getItem('goal');
        chooseTextToShow(goal, totalCashBack);
    };
       
        const chooseTextToShow = (goal, totalCashBackNumber) => {

        let newText = 'You missed out on a lot. Don’t miss out anymore!'

        if (goal === 'save money') {
          newText = 'You could have saved so much!';
        } 
        else  if (goal === 'pay off my debt') {
          newText = 'You could have reduced your debt by heaps!';
        } 
        else  if (goal === 'buy a house') {
          newText = 'This would be great toward your home deposit';
        } 
        else  if (goal === 'go on a dream trip') {
          if (totalCashBackNumber > 10000) {
            newText = 'You could have flown return business to EUROPE!';
          }
          else if (totalCashBackNumber > 5000) {
            newText = 'You could have flown return to EUROPE!';
          }
          else if (totalCashBackNumber > 2000) {
            newText = 'You could have flown return to ASIA!';
          }
          else if (totalCashBackNumber > 1000) {
            newText = 'You could have gone on a weekend away!';
          }
          else if (totalCashBackNumber < 499)
          {
            newText = 'You could have gone on a weekend away';
          }
        } 
      
        setTextToShow(newText)
        console.log(newText);
};
   
//handle based on mobile or web - paramter ingested on landing.

        const handleClick = () => {
        setIsSubmitting(true);
        window.location.href = joinLink.web
      };   
    



return (
<div className="container">
<div className="logo-box">
    {<img src={Logo} alt="Logo" className="logo-image" />}
        
      </div>

      {<MissedRewardsAlt onDataLoaded={handleDataLoaded}/>}
  <div className="header">

<p className="title">{textToShow}</p>
  </div>


  <p className="title">Simply use your Citro Card to make payments
  and earn cashback at your favourite stores:</p>

  {<RewardsList />}

  <p className="body">"These results are indicative only based on your overall purchases and available Citro deals. Citro does not guarantee complete accuracy and has not considered your personal financial circumstances. Deals offered for the above brands are subject to availability."</p>
 
  <button onClick={handleClick} className="submit-email-button" disabled={isSubmitting}>
        {isSubmitting ? 'Redirecting...' : `Join Citro`}</button>

</div>

)
}

export default ShowMissed;