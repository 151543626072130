import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from './Images/Citro_logo.svg';
import ReactGA from 'react-ga4';
import { apis } from '../Config/apis.js';
import { headers } from '../Config/settings.js';

function Consented() {
  const [statusMessage, setStatusMessage] = useState('Confirming Connection...'); // Initial message
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Send a page view event to Google Analytics
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const jobId = searchParams.get('jobId');
    localStorage.setItem('jobId', jobId);


    if (!jobId) {
      // If jobId is not present in the URL, update the status message and don't navigate
      setStatusMessage('We having some trouble connecting to your bank/s, please try again later or contact support@pokitpal.com for assistance.​');
      return; // Stop further execution
    }

    function checkJobStatus(jobId) {
      fetch(apis.checkJob, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ jobId: jobId }),
      })
      .then(response => {
        const status = response.status;
        return response.json().then(data => ({ status, data }));
      })
      .then(({ status, data }) => {
        // Check the API response for a successful status and a cdrid
        if (status === 200 && data.cdrid) {
          // Check if cdrid is not already set in localStorage
          if (!localStorage.getItem('cdrid')) {
            // Set cdrid in localStorage
            localStorage.setItem('cdrid', data.cdrid);
          }
          const cdrid = data.cdrid
          // Navigate to the success page
          navigate(`/ConsentedSuccess?cdrid=${cdrid}`);
        } else if (status === 202) {
          // If the job is still in progress, wait 2 seconds and check again
          setTimeout(() => checkJobStatus(jobId), 2000);
        } else {
          // If the request was not successful, set an error message
          setStatusMessage('We having some trouble connecting to your bank, please try again later or contact support@pokitpal.com for assistance.​');
        }
      })
      .catch(error => {
        console.error('Error fetching job status:', error);
        setStatusMessage('We having some trouble connecting to your bank, please try again later or contact support@pokitpal.com for assistance.​');
      });
    }
    
    
    // Call the function initially with the jobId
    checkJobStatus(jobId);
  }, [location.search, navigate]); // Updated dependencies
    

  return (
<div className="container">
<div className="logo-box">
    {<img src={Logo} alt="Logo" className="logo-image" />}
        
      </div>
  <div className="header">


        <p className="subtitle">
          {statusMessage}
        </p>
            </div> 
    </div>
  );
}

export default Consented;