import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import card from '../assets/card.png';
import RegisterEmail from '../Components/Forms/RegisterEmail';
import Body from './Body';
import { useLocation, useNavigate } from 'react-router-dom';

function ContinueWithEmail() {
  const navigate = useNavigate();
  const location = useLocation();
  const { email, externalId } = location.state || {};

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

    if (email === undefined) {
      navigate(`/`);
      return;
    }
  });

  return (
    <Body backgroundColor="#ffeddb">
      <div className="body-container px-4">
        <div></div>

        <div className="column mb-10">
          <h1 className="header mb-10">Save big with Citro</h1>
          <img src={card} alt="" style={{ maxHeight: 192 }} className="mb-10" />
          <p className="continue-copy text-center mb-6">
            60% of our users have found that they were missing out on more than
            $1000!
          </p>
          <p className="continue-body text-center">
            Use the rewards calculator to find out what you are missing out on.
          </p>
        </div>

        <div className="column pb-16 w-full">
          <RegisterEmail email={email} externalId={externalId} />
        </div>
      </div>
    </Body>
  );
}

export default ContinueWithEmail;
