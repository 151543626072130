import React, { useState } from 'react';
import { headers } from '../../Config/settings.js';

function ConnectButton({ cdrid }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitCDRID = async (cdrid) => {
    try {
      const response = await fetch(
        'https://api.dev.cdr.sipora.io/user/consent',
        {
          method: 'POST',
          headers,
          body: JSON.stringify({ cdrid: cdrid }),
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        // Construct the path and parameters for React Router navigation
        const redirectUrl = responseData.consentLink;
        window.location.href = redirectUrl;
        // Use navigate from react-router-dom for navigation, avoiding window.location.href
      } else {
        // Handle non-successful responses
        console.error('Error Launching Consent:', responseData.message);
        alert(responseData.message || 'Error Launching Consent - Start Again');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error Launching Consent - Start Again');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    await submitCDRID(cdrid);
  };

  return (
    <button
      className="button"
      onClick={handleSubmit}
      type="submit"
      disabled={isSubmitting}
    >
      {isSubmitting ? 'Registering...' : `Click to connect`}
    </button>
  );
}

export default ConnectButton;
