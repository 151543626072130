import React, { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga4';
import { apis } from '../../Config/apis.js';
import { headers } from '../../Config/settings.js';
import { CircularProgress } from '@mui/material';


// Correctly destructure onDataLoaded from props
function MissedRewardsAlt({ onDataLoaded }) {
    const [isLoading, setIsLoading] = useState(true);
    const [totalCashBack, setTotalCashBack] = useState(0); // Use a numerical initial state
    const [totalCashBackAgain, setTotalCashBackAgain] = useState(0); // Use a numerical initial state
    const [uniqueTotalMerchants, setUniqueTotalMerchants] = useState(0); // Assuming you might use it
    

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        setTimeout(() => fetchData(), 10000);
               
    }, [onDataLoaded]);
        // Retrieve cdrid from localStorage
        

        const fetchData = async () => {
            const cdrid = localStorage.getItem('cdrid');
            try {

                const response = await 
                    fetch('https://minerva.sipora.io/api/client/rewards-optimiser/return-rewards', {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify({ cdrid }),
                    }) ;

                if (!response.ok) {
                    throw new Error('API call failed');
                }

               const data = await response.json();
               const rewardValue = data[0].totalRewardValue
               setTotalCashBack(rewardValue)
            
               window.analytics.track('Total Cashback Retrieved', {
                totalMissedCashback: rewardValue,
            });
              
            } catch (error) {
                console.error('API call failed:', error);
                // Optionally, pass error back to parent
                // onDataLoaded(0, error); // Consider modifying onDataLoaded to accept an error
            } finally {
              
                setIsLoading(false);
            }
        };

         // Include onDataLoaded in the dependency array

    useEffect(() => {
        // Update local storage when totalCashBack changes
        // Consider moving this to parent if you want to keep components pure
        const formattedTotalCashBack = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalCashBack);
        localStorage.setItem('totalCashBack', formattedTotalCashBack);
    }, [totalCashBack]);

    return (
        <div className="points-display">
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <div className="points2">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalCashBack)}</div>
                    <div className="points-label">*Potential cashback earnings with Citro
                    </div>
                </>
            )}
        </div>
    );
}

export default MissedRewardsAlt;
