// Wraps content with a container with a background color, backgroundColor
import './reset.css';
import './Body.css';

function Body({ backgroundColor, children }) {
  let style = {
    backgroundColor,
  };

  return (
    <div className="pseudo-body" style={style}>
      {children}
    </div>
  );
}

export default Body;
