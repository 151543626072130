import React, { useEffect, useState } from 'react';
import './shared.css';
import { useNavigate } from 'react-router-dom';
import Logo from './Images/Citro_logo.svg';
import ReactGA from 'react-ga4';


function Income() {
    useEffect(() => {
  
ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }, []);

  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

      const [selectedGoal, setSelectedGoal] = useState(null);

      const handleSelectGoal = (goal) => {
        setSelectedGoal(goal);
      };

      const goals = [
        "Less than $50,000",
        "$50,000 - $99,999",
        "$100,000 - $149,999",
        "More than $150,000"
      ];
      
      const queryParams = new URLSearchParams(window.location.search);
      const cdrid = queryParams.get('cdrid');
      const existingValue = queryParams.get('existing') || 'false'; 
    
      // Optionally set in localStorage if needed elsewhere
      localStorage.setItem('cdrid', cdrid);
      localStorage.setItem('existing', existingValue);
    
      const handleClick = () => {
        localStorage.setItem('incomeLevel', selectedGoal);
        setIsSubmitting(true);
        navigate('/WeCanHelp');
      };

return (
<div className="container">
<div className="logo-box">
    {<img src={Logo} alt="Logo" className="logo-image" />}
        
      </div>
  <div className="header">
    <h1 className="title">How much do you <span style={{ color: 'F1E7B0' }}>make per year</span> </h1>

  <div className="card-box-container">
      {goals.map((goal, index) => (
        <div
          key={index}
          className={`card-box ${selectedGoal === goal ? 'selected' : ''}`}
          onClick={() => handleSelectGoal(goal)}
        >
          {goal}
        </div>
      ))}
    </div>
    </div>
    <button onClick={handleClick} className="submit-email-button" disabled={isSubmitting}>
        {isSubmitting ? 'Loading...' : `Next`}
      </button>



</div>

)
}

export default Income;