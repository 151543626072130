import ReactGA from 'react-ga4';
import React, { useEffect } from 'react';
import './shared.css';
import Logo from './Images/Citro_logo.svg';
import NotifyButton from './Forms/NotifyButton';
import RetrieveTransactions from './Forms/RetrieveTransactions';


function ConsentedSuccess() {
  useEffect(() => {
    // Send a page view event to Google Analytics
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  const queryParams = new URLSearchParams(window.location.search);
  const cdrid = queryParams.get('cdrid');

  // Optionally set in localStorage if needed elsewhere
  localStorage.setItem('cdrid', cdrid);

     
return (
<div className="container">
<div className="logo-box">
    {<img src={Logo} alt="Logo" className="logo-image" />}
        
      </div>
  <div className="header">
  <RetrieveTransactions cdrid={cdrid}/> 
   
    
   
 
     </div>
    
    <p className="subtitle">This might take 2-3 minutes​​</p>
    
</div>

)
}

export default ConsentedSuccess;