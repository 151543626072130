import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { headers } from '../../Config/settings.js';
import { apis } from '../../Config/apis.js';
import { Routes as AppRoutes } from '../../navigation/Routes.js';

function SubmitEmail() {
  const [email, setEmail] = useState('');
  const [tcChecked, setTcChecked] = useState(false);
  const [isEmailDirty, setIsEmailDirty] = useState(false);
  const [isTcCheckedDirty, setIsTcCheckedDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const submitEmail = async (email) => {
    try {
      const response = await fetch(apis.launchRo, {
        method: "POST",
        headers,
        body: JSON.stringify({ email }),
      });

      const responseData = await response.json();

      if (response.ok) {
        const urlString = responseData.roLink;
        const url = new URL(urlString);
        const params = url.searchParams;
        const existingValue = params.get('existing') || 'false';
        if (existingValue !== 'successful') {
          const path = `/${AppRoutes.LaunchConsent}`;
          navigate(`${path}?${params.toString()}`, { state: { email } });
          return;
        }

        // Use navigate from react-router-dom for navigation, avoiding window.location.href
        const path = '/Resume';
        navigate(`${path}?${params.toString()}`);
      } else {
        // Handle non-successful responses
        console.error('Error submitting email:', responseData.message);
        alert(responseData.message || 'Error submitting email');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting the email.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    await submitEmail(email);
  };

  return (
    <form onSubmit={handleSubmit} className="mx-4">
      <div className="input-container flex flex-col mb-10 w-full">
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => {
            setIsEmailDirty(true);
            setEmail(e.target.value);
          }}
          className={isEmailDirty ? 'dirty' : ''}
          required
          autoComplete="email"
        />
      </div>

      <div className="checkbox-container mb-10">
        <label>
          <input
            type="checkbox"
            id="agree"
            value={tcChecked}
            required
            className={isTcCheckedDirty ? 'dirty' : ''}
            onChange={() => {
              setIsTcCheckedDirty(true);
              setTcChecked(!tcChecked);
            }}
          />

          <div className="flex" style={{ gap: '10px' }}>
            {tcChecked ? (
              <svg
                className="checkbox-image"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="#0E0D0C"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="0.5" y="0.5" width="23" height="23" rx="4" />
                <path
                  d="M18.3106 7.18949C18.5918 7.47078 18.7498 7.85224 18.7498 8.24998C18.7498 8.64773 18.5918 9.02919 18.3106 9.31048L10.8106 16.8105C10.5293 17.0917 10.1479 17.2497 9.75012 17.2497C9.35238 17.2497 8.97091 17.0917 8.68962 16.8105L5.68962 13.8105C5.41639 13.5276 5.26519 13.1487 5.26861 12.7554C5.27203 12.3621 5.42978 11.9859 5.70789 11.7078C5.98601 11.4296 6.36223 11.2719 6.75552 11.2685C7.14882 11.2651 7.52772 11.4162 7.81062 11.6895L9.75012 13.629L16.1896 7.18949C16.4709 6.90828 16.8524 6.75031 17.2501 6.75031C17.6479 6.75031 18.0293 6.90828 18.3106 7.18949Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                className="checkbox-image"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="0.5" y="0.5" width="23" height="23" rx="4" />
              </svg>
            )}

            <span className="text-sm label">
              I agree for Citro to collect my personal information in accordance
              with the Citro Privacy Policy, including being contacted by email.
            </span>
          </div>
        </label>
      </div>

      <button
        className="button mx-4"
        type="submit"
        disabled={isSubmitting}
        onClick={() => {
          setIsEmailDirty(true);
          setIsTcCheckedDirty(true);
        }}
      >
        {isSubmitting ? 'Registering...' : `Let's go`}
      </button>
    </form>
  );
}

export default SubmitEmail;
