import React, { useState, useEffect } from 'react';
import { headers } from '../../Config/settings.js';
import { apis } from '../../Config/apis.js';
import { offers } from '../../offers/offers.js';

const loadingMessages = ["Searching through transactions"];

function RewardsList() {
  const [loading, setLoading] = useState(true);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const [businessNames, setBusinessNames] = useState([]);

 

  useEffect(() => {
    const cdrid = localStorage.getItem('cdrid');
    const requestBody = { cdrid };

    fetch(apis.retrieveRewards, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requestBody),
    })
    .then(response => response.json())
    .then(data => {
      // Extract unique business names
      const uniqueBusinessNames = [...new Set(data.map(item => item.enrich.merchant.businessName))];
      
      const filteredBusinessNames = uniqueBusinessNames.filter(businessName =>
        offers.includes(businessName)
      );

      setBusinessNames(filteredBusinessNames);
      setLoading(false);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      setLoading(false);
    });

    const intervalId = setInterval(() => {
      setLoadingMessageIndex(prevIndex => (prevIndex + 1) % loadingMessages.length);
    }, 5000); // Change text every 5 seconds

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      {loading ? (
        <div>
          <p>This might take a few moments...</p>
          <p>{loadingMessages[loadingMessageIndex]}</p>
        </div>
      ) : (
        <div>
          <ul>
            {businessNames.map((businessName, index) => (
              <li key={index}>{businessName}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default RewardsList;
