import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import card from '../assets/card.png';
import SubmitEmail from './Forms/SubmitEmail';
import Body from './Body';

function EntryPageGuest() {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  return (
    <Body backgroundColor="#ffeddb">
      <div className="body-container">
        <div className="spotlight" style={{ backgroundColor: '#fff' }}>
          <div className="column">
            <div className="mb-10">
              <div className="image-header"></div>
              <img className="image" src={card} alt="" />
            </div>

            <div className="column" style={{ maxWidth: '500px' }}>
              <h1 className="header text-center mb-6 mx-4">
                Save big with Citro
              </h1>
              <p className="entry-page-body text-center mb-8 mx-4">
                Use the rewards calculator to find out what you are missing out
                on. Please enter your email so we can help you get started.
              </p>
            </div>
          </div>
          <div className="column pb-16 w-full">
            <SubmitEmail />
          </div>
        </div>
      </div>
    </Body>
  );
}

export default EntryPageGuest;
