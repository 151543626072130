import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import secure from '../assets/secure.png';
import ConnectButton from './Forms/ConnectButton';
import connect from '../assets/icons/connect.svg';
import padlock from '../assets/icons/padlock.svg';
import piggy from '../assets/icons/piggy.svg';
import Body from './Body';

function LaunchConsent() {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  const queryParams = new URLSearchParams(window.location.search);
  const cdrid = queryParams.get('cdrid');

  return (
    <Body backgroundColor="#fff">
      <div className="body-container px-4">
        <div className="column mb-10">
          <div>
            <h1 className="text-left header mb-4">Share transactions</h1>

            <p className="text-left mb-6 gray-body">
              In order to correctly calculate how much a Citro Card could save
              you, we’ll need to know what your spending pattern is like.
            </p>
          </div>
          <img
            src={secure}
            alt=""
            style={{ maxHeight: 120 }}
            className="mb-7"
          />

          <ul>
            <li className="p-4 bottom-border">
              <div className="flex gap-14">
                <img src={connect} alt=""></img>
                <div className="flex flex-col gap-10 items-start">
                  <h2 className="list-item-header text-sm">
                    Connect with your bank
                  </h2>
                  <p className="list-item-body">
                    Log in to your bank account and add your transaction account
                  </p>
                </div>
              </div>
            </li>
            <li className="p-4 bottom-border">
              <div className="flex gap-14">
                <img src={padlock} alt=""></img>
                <div className="flex flex-col gap-10 items-start">
                  <h2 className="list-item-header text-sm">
                    Secure encryption
                  </h2>
                  <p className="list-item-body">
                    Don’t worry, we keep your information completely secure and
                    private
                  </p>
                </div>
              </div>
            </li>
            <li className="p-4">
              <div className="flex gap-14">
                <img src={piggy} alt=""></img>
                <div className="flex flex-col gap-10 items-start">
                  <h2 className="list-item-header text-sm">
                    Savings calculated
                  </h2>
                  <p className="list-item-body">
                    Magic, see how much you could have saved with the Citro Card
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div className="column pb-16 w-full">
          <ConnectButton cdrid={cdrid} />
        </div>
      </div>
    </Body>
  );
}

export default LaunchConsent;
